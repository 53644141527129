.footerContainer {
  color: #fff;
  background-color: var(--theme-primary);
  padding: 3rem 0;

  img {
    max-height: 11.5rem;
    max-width: 400px;
  }
}
