.app-header {
  background-color: var(--theme-light);
  border-bottom: 1px solid var(--theme-lightBorder);
  padding: 0px;

  a {
    color: var(--theme-dark);
    font-weight: bold;
    font-size: 1.2rem;
    padding: 2rem 0.6rem;
    text-decoration: none;
    transition: what 0.4s ease-in-out;
    text-transform: uppercase;
    opacity: 0.8;
    &.active,
    &:hover {
      color: var(--theme-primary) !important;
      opacity: 1;
      background-color: transparent;
    }

    @media screen and (max-width: 990px) {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }
  }

  .dropdown-menu {
    @media screen and (max-width: 990px) {
      padding: 0px;
      border-color: transparent;
    }
    a {
      padding: 5px 0.6rem;

      @media screen and (max-width: 990px) {
        padding-left: 2rem;
      }
    }
  }

  .navbar-brand {
    a {
      opacity: 1;
    }
  }

  .logo {
    max-height: 70px;
    max-width: 400px;
  }
}
