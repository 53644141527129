%menuItem {
  p {
    border-bottom: 1px dotted #4b4b4b;
    display: flex;
    justify-content: space-between;
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
    font-size: 13px;
    span,
    i {
      position: relative;
      background: #fff;
      padding-bottom: 2px;
      bottom: -3px;
    }
  }
}

.foodItem {
  padding: 10px 0px;
  strong {
    font-size: 14px;
  }
  @extend %menuItem;
}

.wineItem,
.vodkaItem {
  @extend %menuItem;
  i {
    margin-left: 5px;
  }
}
