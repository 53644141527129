.collage {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 75vh;

  img {
    position: absolute;
  }

  img:nth-child(1) {
    width: 410px;
    height: 410px;
    top: 0;
    left: -50px;
    z-index: 1;
  }

  img:nth-child(2) {
    width: 340px;
    height: 340px;
    top: 35px;
    right: 40px;
    z-index: 2;
  }

  img:nth-child(3) {
    width: 300px;
    height: 300px;
    top: 350px;
    left: 5px;
    z-index: 2;
  }
}
