.pageBanner {
  position: relative;

  h1 {
    position: relative;
    padding: 80px 0px;
    margin: 0px -15px 0px -15px;
    letter-spacing: 7px;
    font-weight: bold;
    font-size: 19px;
    color: #fff;
    background-position: center top;
    background-color: color-mix(in srgb, var(--theme-primary) 75%, transparent);
  }

  span {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }

  // &:before {
  // z-index: -1;
  // }

  //   background-color: rgba(var(--theme-primary), 0.75);

  //
}
