@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,700");
@import url("https://fonts.googleapis.com/css?family=Open+Sans");

.navbar {
  min-height: 0px;
  margin-bottom: 0px;
}

@import "~bootstrap/scss/bootstrap";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/brands";
// @forward '~@fortawesome/fontawesome-free/scss/regular';

$fontThick: "Source Sans Pro", Helvetica, Arial, Lucida, sans-serif;
$fontText: "Open Sans", Helvetica, Arial, sans-serif;

body {
  font-size: 16px;
}

a {
  text-decoration: none;
}

a .fa-solid {
  margin-right: 0.5rem;
}

.bg-grey {
  background-color: #eaeaea;
}

.img-fluid {
  width: 100%;
}

.header-narrow,
.header-spaced {
  text-transform: uppercase;
  color: #4b4b4b;
}

.header-narrow {
  font-size: 1.63;
  font-family: "Source Sans Pro", Helvetica, Arial, Lucida, sans-serif;
  font-weight: 700;
  margin-top: 0px;
}

.header-spaced {
  color: #4b4b4b;
  margin-bottom: 0.63rem;
  font-size: 1rem;
  letter-spacing: 0.44rem;
  font-weight: bold;
  font-size: 1.19rem;
}

.page-text {
  h3 {
    @extend .header-spaced;
  }
  h1 {
    @extend .header-narrow;
  }
  p {
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
    font-size: 0.88;
    font-weight: 500;
    line-height: 1.5rem;
    word-break: break-word;
  }
}

.menu-list {
  padding-top: 20px;
  column-count: 2;
  column-gap: 30px;
  @media screen and (max-width: 990px) {
    column-count: 1;
  }
}

.foodItem {
  padding: 10px 0px;
  strong {
    font-size: 14px;
    text-transform: capitalize;
  }
}

.placeholde-it {
  display: flex;
  width: 450px;
  height: 450px;
  background-color: var(--theme-primary);
  position: relative;
  justify-content: center;
  align-items: center;

  &:after {
    content: attr(data-text);
    color: #fff;
    display: flex;
    font-weight: 700;
    font-family: "Source Sans Pro", Helvetica, Arial, Lucida, sans-serif;
    font-size: 4rem;
    letter-spacing: -3px;
    text-transform: uppercase;
  }
}
